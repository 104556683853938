import { Chip, Skeleton, Typography, TypographyProps } from '@mui/material';
import { Stack } from '@mui/system';
import { parseISO, format } from 'date-fns/fp';
import { compose, groupBy, isEmpty, partition } from 'ramda';
import { Suspense, lazy } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Match, Round } from '../../app/services/futbolProdeApi';
import { useI18nHelpers, useCompanyTranslations } from '@futbolprode/ui-common';
import { If, Then, Else, When } from 'react-if';

const MatchSummary = lazy(() => import('./MatchSummary'));

interface RoundSummaryProps {
  round: Round;
  selectedDate?: string;
  areMatchesLoading: boolean;
  tournamentId: number;
}

interface RoundMatchesByDateProps extends RoundSummaryProps {
  matches: Match[];
}

const dateWithoutTime = compose(format('yyyy-MM-dd'), parseISO);
const LoadingSkeleton = (
  <Skeleton variant="rectangular" width="100%" height={50} />
);

function RoundMatchesNotDefinedMessage({ round }: { round: Round }) {
  const { roundName } = useCompanyTranslations();
  return (
    <Stack spacing={1} mt={3}>
      <Typography>
        <Trans
          i18nKey="round.matchesNotDefined"
          components={{ b: <strong /> }}
          values={{ roundName: roundName(round) }}
        />
      </Typography>
    </Stack>
  );
}

function RoundSummary({ round, ...props }: RoundSummaryProps) {
  const { t } = useTranslation();

  const roundIsTbd =
    !isEmpty(round.matches) && round.matches.every((m) => m.status === 'TBD');

  if (roundIsTbd) {
    return (
      <TwoWaysSeparatedRoundSummaryByMatch
        date={t('round.TBD')}
        matches={round.matches}
        round={round}
        {...props}
      />
    );
  }
  const matchesPartition = partition(
    (it) => !it.isTwoWaysSecondMatch,
    round.matches,
  );

  return (
    <If condition={isEmpty(round.matches)}>
      <Then>
        <RoundMatchesNotDefinedMessage round={round} />
      </Then>
      <Else>
        {matchesPartition.map((matches) => (
          <RoundMatchesByDate round={round} {...props} matches={matches} />
        ))}
      </Else>
    </If>
  );
}

function RoundMatchesByDate({
  round,
  matches,
  ...props
}: RoundMatchesByDateProps) {
  const { formatDate } = useI18nHelpers();
  const [rescheduledMatches, regularMatches] = partition(
    (it) => it.wasRescheduled,
    matches,
  );

  const MatchGroup = (matches: Match[]) =>
    Object.entries(groupBy((it) => dateWithoutTime(it.date), matches)).map(
      ([date, matches]) => (
        <TwoWaysSeparatedRoundSummaryByMatch
          date={formatDate('PPPP')(parseISO(date))}
          matches={matches}
          // Los partidos reprogramados traen su round
          round={matches[0].round ?? round}
          {...props}
        />
      ),
    );
  return (
    <Stack spacing={{ xs: 2, md: 1 }}>
      {/* Si hay reprogramados que eran de distintas fechas, los mostramos separados */}
      {Object.values(
        groupBy((it) => (it.round?.id ?? '').toString(), rescheduledMatches),
      ).map((rescheduledPerRound) => MatchGroup(rescheduledPerRound))}
      {MatchGroup(regularMatches)}
    </Stack>
  );
}

type RoundSummaryByMatchProps = RoundSummaryProps & {
  matches: Match[];
  date: string;
};

function TwoWaysSeparatedRoundSummaryByMatch({
  date,
  matches,
  round,
  ...props
}: RoundSummaryByMatchProps) {
  const regularMatches = matches.filter((it) => !it.isTwoWaysSecondMatch);
  const secondMatches = matches.filter((it) => it.isTwoWaysSecondMatch);
  const { t } = useTranslation();
  return (
    <>
      {!isEmpty(regularMatches) && (
        <RoundSummaryByMatch
          date={date}
          matches={regularMatches}
          round={round}
          {...props}
        />
      )}
      {!isEmpty(secondMatches) && (
        <>
          <Typography variant="h4" sx={{ mt: 4 }}>
            {t('match.twoWaysSecondMatches')}
          </Typography>
          <RoundSummaryByMatch
            date={date}
            matches={secondMatches}
            round={round}
            {...props}
          />
        </>
      )}
    </>
  );
}

const Subtitle = (props: TypographyProps) => (
  <Typography variant="subtitle1" color="textSecondary" {...props} />
);

function RoundSummaryByMatch({
  date,
  areMatchesLoading,
  matches,
  round,
  tournamentId,
  selectedDate,
}: RoundSummaryByMatchProps) {
  const { t } = useTranslation();
  const { roundName } = useCompanyTranslations();

  const sortedMatches = matches
    .slice()
    .sort((a, b) =>
      a.homeTeam.name > b.homeTeam.name
        ? 1
        : a.homeTeam.name < b.homeTeam.name
        ? -1
        : 0,
    )
    .sort((a, b) => (a.date > b.date ? 1 : -1));

  const allRescheduled = matches.every((it) => it.wasRescheduled);

  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={{ md: 2 }}
        justifyContent={{ md: 'flex-end' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        <Subtitle>
          {roundName(round)?.toUpperCase()}
          {' · '}
          {areMatchesLoading ? t('common.loading') : date}
        </Subtitle>
        <When condition={allRescheduled}>
          <Chip
            label={t('match.wasRescheduled')}
            size="small"
            variant="outlined"
            color="error"
          />
        </When>
      </Stack>
      {sortedMatches.map((match) => (
        <Suspense key={match.id} fallback={LoadingSkeleton}>
          {areMatchesLoading ? (
            LoadingSkeleton
          ) : (
            <MatchSummary
              round={round}
              matchId={match.id}
              key={match.id}
              tournamentId={tournamentId}
              selectedDate={selectedDate}
            />
          )}
        </Suspense>
      ))}
    </>
  );
}

export default RoundSummary;
