import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
// @ts-ignore
import SwipeableViews from 'react-swipeable-views-react-18-fix';
// @ts-ignore
import { autoPlay } from 'react-swipeable-views-utils';
import { NewsDetails } from '../../app/services/futbolProdeApi';
import { take } from 'ramda';
import NewsLink from './NewsLink';
import Skeleton from '@mui/material/Skeleton';
import { stripHtml } from '@futbolprode/ui-common';
import { Stack } from '@mui/material';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const MAX_NEWS_TO_SHOW = 5;

const verticalOverflowSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  lineHeight: 1.2,
};

export default function NewsCarousel({
  newsList,
  isLoading,
}: {
  newsList: NewsDetails[];
  isLoading: boolean;
}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = MAX_NEWS_TO_SHOW;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const activeNews = newsList[activeStep];

  const Stepper = (
    <MobileStepper
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      nextButton={<div />}
      backButton={<div />}
      sx={{
        backgroundColor: 'transparent',
        '& .MuiMobileStepper-dot': {
          backgroundColor: 'secondary.contrastText',
        },
        '& .MuiMobileStepper-dotActive': {
          backgroundColor: 'primary.main',
        },
      }}
    />
  );

  return isLoading ? (
    <>
      <Skeleton
        width="350px"
        height="350px"
        sx={{ backgroundColor: 'text.secondary' }}
      />
      <Skeleton
        width="100%"
        height="50px"
        sx={{ backgroundColor: 'text.secondary' }}
      />
      {Stepper}
    </>
  ) : (
    <Stack sx={{ maxWidth: 400, flexGrow: 1 }} spacing={1.5}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        interval={5000}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {take(MAX_NEWS_TO_SHOW)(newsList).map((news, index) => (
          <NewsLink key={news.title} news={news}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  height: '350px',
                  maxHeight: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
                src={news.imageUrl}
                alt={news.title}
              />
            ) : null}
          </NewsLink>
        ))}
      </AutoPlaySwipeableViews>
      <NewsLink news={activeNews}>
        <Typography
          variant="h4"
          sx={verticalOverflowSx}
          letterSpacing={'-1px'}
          fontWeight={600}
        >
          {activeNews.title}
        </Typography>
      </NewsLink>
      <Typography
        variant="body1"
        sx={verticalOverflowSx}
        fontWeight={500}
        textAlign="justify"
      >
        {stripHtml(activeNews.summary ?? activeNews.description)}
      </Typography>
      {Stepper}
    </Stack>
  );
}
