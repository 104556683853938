import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Alert, Avatar, Paper } from '@mui/material';
import {
  Team,
  Tournament,
  useDreamFinalPredictionControllerPredictionByTournamentQuery,
  useDreamFinalPredictionControllerUpsertMutation,
  useDreamFinalSettingsControllerSettingsByTournamentQuery,
  useTeamsControllerByTournamentQuery,
} from '../../app/services/futbolProdeApi';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isNil, range, update } from 'ramda';
import { useDebounce } from 'use-debounce';
import { useCompanyTranslations } from '@futbolprode/ui-common';
import TournamentLogo from '../../common/TournamentLogo';
import DreamFinalTeamSelector from './DreamFinalTeamSelector';

export function DreamFinalAccordion({
  tournament,
}: {
  tournament: Tournament;
}) {
  const { t } = useTranslation();
  const { roundName } = useCompanyTranslations();

  const [expanded, setExpanded] = React.useState(false);

  const { data, isLoading: dreamFinalSettingsAreLoading } =
    useDreamFinalSettingsControllerSettingsByTournamentQuery(
      tournament.id.toString(),
    );

  const { data: dreamFinalPrediction, isLoading: predictionLoading } =
    useDreamFinalPredictionControllerPredictionByTournamentQuery(
      tournament.id.toString(),
    );

  const { data: teams, isLoading: teamsAreLoading } =
    useTeamsControllerByTournamentQuery(tournament.id.toString());

  const [chosenTeams, setChosenTeams] = React.useState<(Team | null)[]>([
    null,
    null,
    null,
    null,
  ]);

  React.useEffect(
    () =>
      setChosenTeams([
        dreamFinalPrediction?.team1 ?? null,
        dreamFinalPrediction?.team2 ?? null,
        dreamFinalPrediction?.team3 ?? null,
        dreamFinalPrediction?.team4 ?? null,
      ]),
    [dreamFinalPrediction],
  );

  const [saved, setSaved] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [debouncedTouched] = useDebounce(touched, 1000);

  const [upsertPrediction, upsertResult] =
    useDreamFinalPredictionControllerUpsertMutation();
  const isUpserting = upsertResult.isLoading;

  React.useEffect(() => {
    const predictionUpsertFn = async () => {
      if (!debouncedTouched || !touched) return;
      setTouched(false);
      const upsertBody = {
        tournament: { id: tournament.id },
        team1: chosenTeams[0] ? { id: chosenTeams[0]?.id } : undefined,
        team2: chosenTeams[1] ? { id: chosenTeams[1]?.id } : undefined,
        team3: chosenTeams[2] ? { id: chosenTeams[2]?.id } : undefined,
        team4: chosenTeams[3] ? { id: chosenTeams[3]?.id } : undefined,
      };
      await upsertPrediction(upsertBody).unwrap();
      setSaved(true);
      setTimeout(() => setSaved(false), 3000);
    };
    predictionUpsertFn();
  }, [
    upsertPrediction,
    chosenTeams,
    dreamFinalPrediction,
    tournament.id,
    debouncedTouched,
    touched,
  ]);

  const options = (teams ?? []).filter(
    (it) => !chosenTeams.map((it2) => it2?.id).includes(it.id),
  );

  const teamAutocompletes = range(1, (data?.awardedPositions ?? 0) + 1).map(
    (it: number) => (
      <DreamFinalTeamSelector
        position={it}
        isLoading={teamsAreLoading || predictionLoading || isNil(teams)}
        value={chosenTeams[it - 1]}
        options={options}
        onChange={(value) => {
          setChosenTeams(update(it - 1, value, chosenTeams));
          setTouched(true);
        }}
        disabled={
          data?.limitRound
            ? new Date(data?.limitRound.date) < new Date()
            : false
        }
      />
    ),
  );

  if (dreamFinalSettingsAreLoading || !data || !data.isEnabled) return null;
  const summaryBorderRadius = '15px 15px 0 0';
  return (
    <>
      <Accordion
        disableGutters
        expanded={expanded}
        onChange={(_e, _expanded) => setExpanded((prev) => !prev)}
        sx={{ '&.MuiAccordion-root': { borderRadius: summaryBorderRadius } }}
      >
        <AccordionSummary
          expandIcon={
            <TournamentLogo>
              <Avatar
                src={tournament.logoUrl}
                sx={{
                  width: '40px',
                  height: '40px',
                  transform: 'none!important',
                }}
              />
            </TournamentLogo>
          }
          sx={{
            borderRadius: summaryBorderRadius,
            backgroundColor: 'secondary.main',
            '& .MuiAccordionSummary-expandIconWrapper': {
              transition: 'none',
              transform: 'none!important',
            },
          }}
        >
          <Typography
            color="secondary.contrastText"
            fontWeight={600}
            variant="h6"
          >
            {t('dreamFinal.word')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderRadius: '20px 20px 0 0',
          }}
        >
          <Alert severity="success" icon={false}>
            <Typography variant="body2">
              {t('dreamFinal.chooseTeams', {
                ...data,
                position: roundName(data?.limitRound),
              })}
            </Typography>
          </Alert>
          {teamAutocompletes}
        </AccordionDetails>
      </Accordion>
      <Paper
        sx={{
          cursor: 'pointer',
          borderRadius: '0 0 15px 15px',
          marginBottom: 2,
          p: 1,
          textAlign: 'right',
          display: 'flex',
          justifyContent: 'flex-end',
          alignContent: 'center',
        }}
        onClick={() => setExpanded((prev) => !prev)}
      >
        {isUpserting || saved ? (
          <Typography
            sx={{ display: 'flex', alignSelf: 'flex-start', flexGrow: 1 }}
          >
            {t(isUpserting ? 'matchSummary.saving' : 'matchSummary.saved')}
          </Typography>
        ) : null}
        {expanded
          ? t('dreamFinal.close')
          : dreamFinalPrediction
          ? t('dreamFinal.seeTeams')
          : t('dreamFinal.selectTeams')}{' '}
        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Paper>
    </>
  );
}
