import { Box, Grid } from '@mui/material';
import { NewsSummary } from '../features/news/NewsSummary';
import ScrollToTopFab from './ScrollToTopFab';
import { Banner } from '../features/summary/Banner';
import { useMemo } from 'react';
import { When, Unless } from 'react-if';
import { isNil } from 'ramda';
import { Announcement } from '@futbolprode/ui-common';
import Home from '../Home';
import { AppContainer } from './AppContainer';
import useCompanySettingsQuery from '../common/hooks/useCompanySettingsQuery';

export default function HomeLayout() {
  const { data } = useCompanySettingsQuery();
  const { company, hideNewsFromHome } = data;

  const hasSuperiorBanner = useMemo(
    () => !isNil(company?.superiorBanner),
    [company],
  );

  const showUpperSection = useMemo(
    () =>
      company?.mainPageAnnouncementIsEnabled ||
      hasSuperiorBanner ||
      !hideNewsFromHome,
    [company, hasSuperiorBanner, hideNewsFromHome],
  );

  return (
    <>
      <When condition={showUpperSection}>
        <Box
          pb={4}
          sx={{
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText',
          }}
        >
          <AppContainer sx={{ pt: 0 }}>
            <Grid container rowGap={2}>
              <Grid item xs={12}>
                <Announcement
                  isEnabled={company?.mainPageAnnouncementIsEnabled}
                  messageKey="announcements.mainPage"
                  severity={company?.mainPageAnnouncementSeverity}
                />
              </Grid>
              <When condition={hasSuperiorBanner}>
                <Grid item xs={12}>
                  <Banner
                    banner={company?.superiorBanner}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      maxHeight: '150px',
                    }}
                  />
                </Grid>
              </When>
              <Unless condition={hideNewsFromHome}>
                <NewsSummary />
              </Unless>
            </Grid>
          </AppContainer>
        </Box>
      </When>
      <AppContainer>
        <Box my={4}>
          <Home />
        </Box>
      </AppContainer>
      <ScrollToTopFab />
    </>
  );
}
