import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RankingCard from './RankingCard';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useRef } from 'react';
import Slider from 'react-slick';
import './UserPositionsSummary.css';
import { useRankingsControllerLatestRankingsMeQuery } from '../../app/services/futbolProdeApi';
import { min } from 'ramda';
import { useTranslation } from 'react-i18next';

export default function UserPositionsSummary() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const sliderRef: any = useRef(null);

  const { data, isLoading } = useRankingsControllerLatestRankingsMeQuery();
  if (!data || isLoading) return null;
  const theTournament = data.tournaments?.[0];
  const oneTournamentChildren = [
    [data.global.position, true, t('ranking.global')],
    [
      data.global.points + (data.global.dreamFinalPoints ?? 0),
      false,
      t('ranking.pointsInTournament'),
    ],
    [
      theTournament?.currentRound?.position,
      true,
      t('ranking.positionInRoundFullText', {
        number: theTournament?.currentRound?.round.position,
      }),
    ],
    [
      theTournament?.currentRound?.points,
      false,
      t('ranking.pointsInRound', {
        number: theTournament?.currentRound?.round.position,
      }),
    ],
  ].map(([number, isPosition, name]) => (
    <Card sx={{ borderRadius: 5, backgroundColor: 'secondary.main' }}>
      <CardContent sx={{ p: 0, pt: 1, pl: 2 }}>
        <Typography variant="h6" color="secondary.contrastText">
          {name}
        </Typography>
      </CardContent>
      <CardActions
        sx={{ textAlign: 'right', justifyContent: 'flex-end', mx: 2, p: 0 }}
      >
        <Typography
          variant="h2"
          color="secondary.contrastText"
          fontWeight={600}
        >
          {number}
          {isPosition ? '°' : null}
        </Typography>
      </CardActions>
    </Card>
  ));

  if (data?.tournaments?.length <= 1) {
    return (
      <Grid item xs={12} position="relative">
        <RankingSlider
          sliderRef={sliderRef}
          slidesToShow={isMobile ? 1 : 4}
          visibleArrows={isMobile}
        >
          {oneTournamentChildren}
        </RankingSlider>
      </Grid>
    );
  }
  return (
    <Grid item xs={12} position="relative">
      <RankingSlider
        visibleArrows
        sliderRef={sliderRef}
        slidesToShow={min(
          isMobile ? 1 : 3,
          (data?.tournaments.length ?? 0) + 1,
        )}
      >
        {isLoading || !data ? (
          <Skeleton />
        ) : (
          <RankingCard ranking={data.global} />
        )}
        {isLoading || !data ? (
          <Skeleton />
        ) : (
          data?.tournaments.map((it) => <RankingCard tournamentRanking={it} />)
        )}
      </RankingSlider>
    </Grid>
  );
}

function RankingSlider({
  children,
  sliderRef,
  slidesToShow,
  visibleArrows,
}: {
  children: React.ReactNode;
  sliderRef: any;
  slidesToShow: number;
  visibleArrows: boolean;
}) {
  return (
    <>
      <KeyboardArrowLeftIcon
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '50%',
          left: '-2px',
          display: visibleArrows ? 'initial' : 'none',
        }}
        onClick={(e) => {
          e.preventDefault();
          if (sliderRef.current) {
            sliderRef.current.slickPrev();
          }
        }}
      />
      <Slider ref={sliderRef} slidesToShow={slidesToShow} arrows={false}>
        {children}
      </Slider>
      <KeyboardArrowRightIcon
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: '50%',
          right: '-16px',
          display: visibleArrows ? 'initial' : 'none',
        }}
        onClick={(e) => {
          e.preventDefault();
          if (sliderRef.current) {
            sliderRef.current.slickNext();
          }
        }}
      />
    </>
  );
}
