import { Outlet, useLocation } from 'react-router-dom';
import WithAppBar from '../common/WithAppBar';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useAuth } from '@futbolprode/ui-common';
import { isNil } from 'ramda';

declare global {
  interface Window {
    AndroidApp: {
      // JavascriptInterface solo funciona con tipos primitivos
      identifyCurrentUser: (userJson: string) => void;
    };
  }
}

export default function AppLayout() {
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (isNil(user) || isNil(window.AndroidApp)) {
      return;
    }

    window.AndroidApp.identifyCurrentUser(JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send({ hitType: 'pageview' });
    }
  }, [location]);

  return (
    <WithAppBar>
      <Outlet />
    </WithAppBar>
  );
}
