import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MenuIcon from '@mui/icons-material/Menu';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { logout, useCompanyTranslations } from '@futbolprode/ui-common';
import { useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import { Image } from 'mui-image';
import { useCompanyRoutes } from '../router';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Company,
  useCompaniesControllerFindMeWithAreasQuery,
  useTournamentsControllerFindAllByCompanyQuery,
  useUsersControllerFindMeQuery,
  useAwardsControllerFindAllQuery,
} from '../app/services/futbolProdeApi';
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { setTabIndex } from '../features/rankings/activeRankingSlice';
import { CSSTransition } from 'react-transition-group';
import './WithAppBar.css';
import { isEmpty, isNil } from 'ramda';
import { useCompanyCustomBackgroundColor } from '../common/hooks/useCompanyCustomColors';
import { useTour } from '@reactour/tour';
import { Unless, When } from 'react-if';
import { compact, isNilOrEmpty, onlyIf } from './ramdaExtensions';
import { styled } from '@mui/material';

const USER_MENU_CONTENT = 'user-profile-menu-content';
const MENU_BUTTON_ID = 'menu-appbar-button';

type Page =
  | {
      name: string;
      margin?: number;
      to: string;
      noTranslation?: boolean;
      textColor?: string;
    }
  | {
      name: string;
      margin?: number;
      action: any;
      noTranslation?: boolean;
      textColor?: string;
    };

const pageI18nKey = (page: Page) => `sidebar.pages.${page.name}`;

const RoundedBackgroundNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    '& .MuiBottomNavigationAction-wrapper': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.tertiary.contrastText,
      backgroundColor: theme.palette.tertiary.main,
      borderRadius: '50%',
      padding: theme.spacing(0.25),
      fontSize: 'xxx-large',
    },
  }),
);

const bottomPages = [
  {
    name: 'termsAndConditions.pages.howToPlay',
    to: 'gamerules',
  },
  {
    name: 'termsAndConditions.pages.word',
    to: 'termsandconditions',
  },
  {
    name: 'termsAndConditions.pages.support',
    to: 'profile/support',
  },
];

export const toggleUserProfileMenuOpen = () => {
  document.getElementById(MENU_BUTTON_ID)?.click();
};

function CompanyLogo({
  company,
  isLoading,
  width,
}: {
  company?: Company;
  isLoading: boolean;
  width: number;
}) {
  return isLoading ? (
    <Skeleton width={width} height={50} sx={{ bgcolor: 'grey.900' }} />
  ) : (
    <Image
      src={company?.logoUrl ?? ''}
      alt={company?.name ?? ''}
      width={width}
    />
  );
}

export function WithAppBar({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { appRoute } = useCompanyRoutes();
  const { t, i18n } = useTranslation();
  const { tournamentName } = useCompanyTranslations();
  const navigate = useNavigate();
  const { data: user } = useUsersControllerFindMeQuery();
  const { data: company, isLoading: companyLoading } =
    useCompaniesControllerFindMeWithAreasQuery();
  const { data: tournaments } = useTournamentsControllerFindAllByCompanyQuery();
  const theme = useTheme();
  const { setIsOpen: setTourIsOpen, setCurrentStep: setTourCurrentStep } =
    useTour();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const hideNews = company?.disableNews;
  const { data: awards, isLoading: awardsLoading } =
    useAwardsControllerFindAllQuery();

  const [usesRankingsAppBar, setUsesRankingsAppBar] = React.useState(false);
  const pages: (
    | { name: string; to: string }
    | { name: string; action: () => void }
  )[] = compact([
    onlyIf(!isMobile, {
      name: 'play',
      to: '',
    }),
    onlyIf(!companyLoading && !hideNews, {
      name: 'news',
      to: 'news',
    }),
    onlyIf(!isMobile, {
      name: 'positions',
      action: () => setUsesRankingsAppBar((prev: boolean) => !prev),
    }),
    onlyIf(isMobile, {
      name: 'positions',
      to: 'positions',
    }),
    onlyIf(!awardsLoading && awards?.some((it) => !isNilOrEmpty(it.name)), {
      // t('sidebar.pages.awards')
      name: 'awards',
      to: 'awards',
    }),
    onlyIf(!companyLoading && !company?.disableTrivias, {
      name: 'trivia',
      to: 'trivia',
    }),
  ]);

  const settings = [
    {
      name: t('profile.word'),
      action: () => navigate(appRoute('profile')),
    },
    {
      name: t('tour.replay'),
      action: () => {
        setTourCurrentStep(0);
        setTourIsOpen(true);
      },
    },
    {
      name: t('user.logout'),
      action: () => dispatch(logout()),
    },
  ];

  const rankingPages = [
    {
      name: t('sidebar.pages.globalRanking'),
      action: () => {
        dispatch(setTabIndex({ tabIndex: 0 }));
        navigate(appRoute('positions'));
      },
    },
    ...(tournaments ?? []).map((it, idx) => ({
      name: t('ranking.in', { name: tournamentName(it) }),
      action: () => {
        dispatch(setTabIndex({ tabIndex: idx + 1 }));
        navigate(appRoute('positions'));
      },
    })),
  ];

  if (!isNil(company?.areas) && !isEmpty(company?.areas)) {
    rankingPages.push({
      name: t('sidebar.pages.areasRanking', {
        areaName: t(`user.areaLevel1`, { ns: 'company' }),
      }),
      action: () => {
        dispatch(setTabIndex({ tabIndex: 0 }));
        navigate(appRoute('positions/areas?areaLevel=1'));
      },
    });
  }

  const pageButton = (page: Page) => (
    <Button
      id={`navbar-page-${page.name}`}
      key={page.name}
      onClick={
        'to' in page
          ? handleCloseNavMenu(page.to)
          : 'action' in page
          ? () => page.action()
          : undefined
      }
      sx={{
        m: page.margin,
        color: page.textColor ?? 'primary.contrastText',
        display: 'block',
      }}
    >
      <span style={{ whiteSpace: 'nowrap' }}>
        {page.noTranslation ? page.name : t([pageI18nKey(page), page.name])}
      </span>
    </Button>
  );

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (to: string) => () => {
    setAnchorElNav(null);
    navigate(appRoute(to));
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const closeUserMenuAndThen = (action: () => void) => () => {
    handleCloseUserMenu();
    action();
  };

  const rankingsAppBarRef = React.useRef(null);

  const { backgroundColor, color } = useCompanyCustomBackgroundColor(
    'navigationBarBackground',
    'primary.main',
  );

  React.useEffect(() => {
    i18n.changeLanguage(searchParams.get('lng') ?? user?.preferredLanguage);
  }, [i18n, searchParams, user?.preferredLanguage]);

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor,
          '& .MuiButton-text': { color },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ margin: '0 auto' }}>
              <Link to={appRoute()}>
                <CompanyLogo
                  company={company}
                  isLoading={companyLoading}
                  width={150}
                />
              </Link>
            </Box>
            <Unless condition={isMobile}>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => pageButton({ ...page, margin: 2 }))}
              </Box>
            </Unless>

            <Unless condition={isMobile}>
              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'initial' } }}>
                <Tooltip title={t('sidebar.settings')}>
                  <IconButton
                    id={MENU_BUTTON_ID}
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0 }}
                  >
                    <Avatar
                      src={user?.profilePictureUrl ?? ''}
                      alt={user?.name ?? ''}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  disableScrollLock
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Box id={USER_MENU_CONTENT}>
                    {settings.map(({ name, action }) => (
                      <MenuItem
                        key={name}
                        onClick={closeUserMenuAndThen(action)}
                      >
                        <Typography textAlign="center">{name}</Typography>
                      </MenuItem>
                    ))}
                  </Box>
                </Menu>
              </Box>
            </Unless>
          </Toolbar>
        </Container>
        <CSSTransition
          mountOnEnter
          unmountOnExit
          nodeRef={rankingsAppBarRef}
          in={usesRankingsAppBar}
          timeout={300}
          classNames="rankingsAppBar"
        >
          <div style={{ backgroundColor: theme.palette.tertiary.main }}>
            <Container ref={rankingsAppBarRef} maxWidth="xl">
              <Box
                sx={{
                  justifyContent: 'center',
                  display: {
                    xs: 'none',
                    md: 'flex',
                    justifyContent: 'flex-start',
                  },
                }}
              >
                {rankingPages.map((page) =>
                  pageButton({
                    ...page,
                    noTranslation: true,
                    textColor: 'tertiary.contrastText',
                  }),
                )}
              </Box>
            </Container>
          </div>
        </CSSTransition>
      </AppBar>
      {children}
      <Paper
        sx={{
          bottom: 0,
          left: 0,
          right: 0,
          display: { xs: 'none', md: 'flex' },
          height: '100%',
          minHeight: '10vh',
          backgroundColor: 'primary.main',
          alignItems: 'center',
          justifyContent: 'space-around',
          py: 2,
          gap: '20vw',
        }}
      >
        <Link to={appRoute()}>
          <CompanyLogo
            company={company}
            isLoading={companyLoading}
            width={250}
          />
        </Link>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {bottomPages.map((page) => pageButton({ ...page, margin: 2 }))}
        </Box>
      </Paper>
      <When condition={isMobile}>
        <Paper
          sx={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            display: { xs: 'block', md: 'none' },
          }}
          elevation={3}
        >
          <BottomNavigation
            sx={{ backgroundColor: 'primary.main', placeItems: 'center' }}
            showLabels
            onChange={(_event, newValue) => {
              navigate(appRoute(newValue));
            }}
          >
            <BottomNavigationAction
              id="bottom-navbar-positions"
              sx={{ color: 'primary.contrastText' }}
              label={t('ranking.word')}
              value="positions"
              icon={<LeaderboardIcon />}
            />
            {!hideNews && (
              <BottomNavigationAction
                sx={{ color: 'primary.contrastText' }}
                label={t('news.word')}
                value="news"
                icon={<NewspaperIcon />}
              />
            )}
            <RoundedBackgroundNavigationAction
              value=""
              icon={<SportsSoccerIcon fontSize="inherit" />}
            />
            <BottomNavigationAction
              sx={{ color: 'primary.contrastText' }}
              label={t('profile.word')}
              value="profile"
              icon={<PersonIcon />}
            />
            <Box p={2}>
              <IconButton
                id={MENU_BUTTON_ID}
                size="small"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ color: 'primary.contrastText' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={() => setAnchorElNav(null)}
                sx={{
                  display: { xs: 'block', md: 'none' },
                  '& .MuiPaper-root': {
                    backgroundColor: 'primary.main',
                  },
                }}
              >
                <Box id={USER_MENU_CONTENT}>
                  {[...pages, ...settings].map(
                    (
                      page:
                        | { name: string; action: any }
                        | { to: string; name: string },
                    ) => (
                      <MenuItem
                        id={`navbar-page-${page.name}`}
                        key={page.name}
                        onClick={
                          'to' in page
                            ? handleCloseNavMenu(page.to)
                            : () => page.action()
                        }
                      >
                        <Typography
                          textAlign="center"
                          color="primary.contrastText"
                        >
                          {t(pageI18nKey(page), { defaultValue: page.name })}
                        </Typography>
                      </MenuItem>
                    ),
                  )}
                </Box>
              </Menu>
            </Box>
          </BottomNavigation>
        </Paper>
      </When>
    </>
  );
}
export default WithAppBar;
