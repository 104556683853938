import { memo, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  FormHelperText,
  InputAdornment,
  IconButton,
  Alert,
  AlertColor,
  Box,
  Stack,
  Button,
  Divider,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Formik } from 'formik';
import { createValidator } from 'class-validator-formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  LoginUserDto,
  useAuthControllerGoogleLoginMutation,
  useAuthControllerLoginMutation,
} from '../../../app/services/futbolProdeApi';
import { instanceToPlain } from 'class-transformer';
import { useTranslation } from 'react-i18next';
import { If, Then, Else, When } from 'react-if';
import { CreateUserDto } from './CreateUserDto';
import { Routes, useCompanyRoutes } from '../../../router';
import { LoginStrategy } from '../signup/LoginStrategy';
import { AdmissionFormProps } from '../AdmissionContainer';
import { Input } from '../signup/Input';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleIcon from '@mui/icons-material/Google';
import { Announcement } from '@futbolprode/ui-common';

function LoginForm({ company }: AdmissionFormProps) {
  const { appRoute } = useCompanyRoutes();
  const { state } = useLocation();
  const [login, result] = useAuthControllerLoginMutation();
  const [serverGoogleLogin, googleResult] =
    useAuthControllerGoogleLoginMutation();
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleLogin = async (c: CreateUserDto) => {
    await login(instanceToPlain(c) as LoginUserDto).unwrap();
    navigate(state?.from?.pathname ?? appRoute());
  };

  const handleSubmit = (values: CreateUserDto) => {
    handleLogin(values);
  };

  const user = new CreateUserDto();
  const [searchParams] = useSearchParams();
  const verifyEmail = searchParams.get('verify');
  const recoverEmail = searchParams.get('recover');
  const comesFromSignup = searchParams.get('signup') === 'true';
  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      serverGoogleLogin({ accessToken: codeResponse.access_token }).then(() => {
        navigate(state?.from?.pathname ?? appRoute());
      });
    },
    onError: (error: any) => console.log('Login Failed:', error),
  });

  const showLoginWithGoogle = useMemo(
    () =>
      company?.loginStrategy === LoginStrategy.byEmail &&
      !company?.disableGoogleLogin,
    [company],
  );

  // t('login.errors.ACCOUNT_RECOVERY_PENDING')
  // t('login.errors.EMAIL_VERIFICATION_PENDING')
  const errorCode = (result.error as any)?.data?.error;

  return (
    <>
      <Formik
        initialValues={user}
        onSubmit={handleSubmit}
        validate={createValidator(CreateUserDto)}
        validateOnChange={false}
      >
        {({ handleSubmit, handleChange, values, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <Box mb={2}>
                  {company?.disableRegister ? (
                    <></>
                  ) : (
                    <Grid item xs={12} sx={{ marginBottom: 3 }}>
                      <span style={{ color: 'inherit' }}>
                        {t(
                          company?.loginStrategy === LoginStrategy.byExternalId
                            ? 'login.activateAccountQuestion'
                            : 'login.noAccountQuestion',
                        )}
                      </span>{' '}
                      <Link
                        to={'../' + Routes.SIGN_UP}
                        style={{ color: 'inherit' }}
                      >
                        {t(
                          company?.loginStrategy === LoginStrategy.byExternalId
                            ? 'login.activate'
                            : 'login.signup',
                        )}
                      </Link>
                    </Grid>
                  )}
                  <When condition={verifyEmail}>
                    <Grid item xs={12}>
                      <Alert severity="info">
                        {t('login.verifyMailSent', { email: verifyEmail })}
                      </Alert>
                    </Grid>
                  </When>
                  <When condition={recoverEmail}>
                    <Grid item xs={12}>
                      <Alert severity="info">
                        {t('login.recoverMailSent', { email: recoverEmail })}
                      </Alert>
                    </Grid>
                  </When>
                  <When condition={comesFromSignup}>
                    <Grid item xs={12}>
                      <Alert severity="success">
                        {t('login.accountCreated')}
                      </Alert>
                    </Grid>
                  </When>
                  <Grid item xs={12}>
                    <Announcement
                      isEnabled={company?.loginAnnouncementIsEnabled}
                      messageKey="announcements.login"
                      sx={{
                        my: 2,
                        height: 'auto',
                      }}
                      severity={
                        company?.loginAnnouncementSeverity as AlertColor
                      }
                    />
                  </Grid>
                </Box>
              </Grid>
              <If condition={LoginStrategy.byEmail === company?.loginStrategy}>
                <Then>
                  <Grid item xs={12}>
                    <Input
                      value={values.email}
                      name={'email'}
                      label={t('login.email')}
                      onChange={handleChange}
                      error={errors.email}
                    />
                  </Grid>
                </Then>
                <Else>
                  <Grid item xs={12}>
                    <Input
                      value={values.externalId}
                      name={'externalId'}
                      label={t('user.identifier', { ns: 'company' })}
                      onChange={handleChange}
                      error={errors.externalId}
                    />
                  </Grid>
                </Else>
              </If>
              <Grid item xs={12}>
                <Input
                  value={values.password}
                  name={'password'}
                  label={t('login.password')}
                  onChange={handleChange}
                  error={errors.password}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          edge="end"
                          tabIndex={-1}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormHelperText error>
                  {result.error &&
                    t([`login.errors.${errorCode}`, 'login.userInvalid'])}
                  {googleResult.error && t('login.googleUserInvalid')}
                </FormHelperText>
              </Grid>

              <Grid item xs={12} sx={{ marginBottom: 3 }}>
                <Link
                  to={'../' + Routes.INIT_RECOVER}
                  style={{ color: 'inherit' }}
                >
                  {t('activation.forgotPassword')}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={result.isLoading}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    fontWeight: 600,
                    textTransform: 'capitalize',
                  }}
                >
                  {t('login.signin')}
                </LoadingButton>
              </Grid>
            </form>
          );
        }}
      </Formik>
      <When condition={showLoginWithGoogle}>
        <Stack spacing={1} pt={5}>
          <Divider sx={{ '&::before, &::after': { position: 'static' } }}>
            <Typography color="inherit">{t('login.orContinueWith')}</Typography>
          </Divider>
          <Stack alignItems="center">
            <Button
              sx={{ width: '33%' }}
              variant="outlined"
              onClick={() => loginWithGoogle()}
            >
              <GoogleIcon />
            </Button>
          </Stack>
        </Stack>
      </When>
    </>
  );
}

export default memo(LoginForm);
