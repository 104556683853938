import { useTranslation } from 'react-i18next';
import {
  Ranking,
  TournamentRoundRanking,
} from '../../app/services/futbolProdeApi';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import { isNil } from 'ramda';
import Image from 'mui-image';
import { useCompanyTranslations } from '@futbolprode/ui-common';
import TournamentLogo from '../../common/TournamentLogo';

export default function RankingCard({
  ranking,
  tournamentRanking,
}: {
  ranking?: Ranking;
  tournamentRanking?: TournamentRoundRanking;
}) {
  const { t } = useTranslation();
  const { tournamentName } = useCompanyTranslations();

  return (
    <Card>
      <CardContent
        sx={{
          backgroundColor: 'primary.main',
          color: 'primary.contrastText',
          px: 0,
          '&:last-child': {
            py: 0,
          },
        }}
      >
        <Stack direction="row">
          <Grid
            xs={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              display: {
                xs: 'none',
                md: tournamentRanking ? 'initial' : 'none',
              },
            }}
          >
            <TournamentLogo>
              <Image
                src={tournamentRanking?.ranking.tournament.logoUrl!}
                alt={tournamentRanking?.ranking.tournament?.name}
                style={{ objectFit: 'contain', height: '15vh' }}
              />
            </TournamentLogo>
          </Grid>
          <Grid
            container
            xs={12}
            md={tournamentRanking ? 8 : 12}
            sx={{ textAlign: 'center' }}
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Typography
                noWrap
                color="primary.contrastText"
                variant="h6"
                p={2}
              >
                {tournamentRanking
                  ? tournamentName(tournamentRanking?.ranking.tournament)
                  : t('ranking.global')}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ backgroundColor: 'secondary.main' }} p={2}>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                <Grid item xs={6} direction="column">
                  <Typography
                    noWrap
                    color="secondary.contrastText"
                    variant="button"
                  >
                    {t(
                      tournamentRanking
                        ? 'ranking.tournament'
                        : 'ranking.global',
                    )}
                  </Typography>
                  <Typography variant="h3" color="white">
                    {tournamentRanking
                      ? isNil(tournamentRanking.ranking.position)
                        ? '-'
                        : `${tournamentRanking.ranking.position}°`
                      : isNil(ranking?.position)
                      ? '-'
                      : `${ranking?.position}°`}
                  </Typography>
                </Grid>
                <Grid item xs={6} direction="column">
                  <Typography
                    noWrap
                    color="secondary.contrastText"
                    variant="button"
                  >
                    {tournamentRanking
                      ? t('ranking.positionInRound', {
                          number:
                            tournamentRanking.currentRound?.round.position ??
                            '-',
                        })
                      : t('ranking.totalPoints')}
                  </Typography>
                  <Typography variant="h3" color="white">
                    {tournamentRanking
                      ? tournamentRanking.currentRound?.position ?? '-'
                      : (ranking?.points ?? 0) +
                        (ranking?.dreamFinalPoints ?? 0)}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}
